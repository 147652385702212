import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      // Informações de contato
      email: localStorage.getItem('email') || '',
      userName: localStorage.getItem('userName') || '',
      fullname: localStorage.getItem('fullname') || '',
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',

      // Detalhes do perfil
      pfp: localStorage.getItem('pfp') || '', // Profile picture
      avatar: localStorage.getItem('avatar') || '',
      country: localStorage.getItem('country') || '',
      notes: localStorage.getItem('notes') || '',

      // Configurações de segurança
      is2FAEnabled: localStorage.getItem('is2FAEnabled') === 'true',

      // Status do usuário
      memberSince: localStorage.getItem('memberSince') || '',
      active: localStorage.getItem('active') === 'true',
      starred: localStorage.getItem('starred') === 'true',
      hasReport: localStorage.getItem('hasReport') === 'true',
      status: localStorage.getItem('status') || '',
      checked: localStorage.getItem('checked') === 'true',

      // Informações adicionais
      trend: localStorage.getItem('trend') || '',
      color: localStorage.getItem('color') || '',
      graph: localStorage.getItem('graph') || '',
      graphColor: localStorage.getItem('graphColor') || '',
      role: localStorage.getItem('role') || '',

      // Assinaturas
      plan_id: localStorage.getItem('plan_id') || '',
      end_date: localStorage.getItem('end_date') || '',
      is_active: localStorage.getItem('is_active') === 'true',

      isLoggedIn: !!localStorage.getItem('token'), // Verificar se o token está presente
      keepLoggedIn: localStorage.getItem('keepLoggedIn') === 'true', // Adicionar estado para "Keep me signed in"
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
      localStorage.setItem('is2FAEnabled', this.is2FAEnabled.toString())
    },

    changeFirstName(firstName: string) {
      this.firstName = firstName
      localStorage.setItem('firstName', firstName)
    },
    changeFullName(newFullName: string) {
      this.fullname = newFullName
      localStorage.setItem('fullname', newFullName)
    },

    changeUserName(userName: string) {
      this.userName = userName
      localStorage.setItem('userName', userName)
    },

    login(
      userData: {
        firstName: string
        userName: string
        email: string
        memberSince: string
        pfp: string
        is2FAEnabled: boolean
        avatar: string
        country: string
        notes: string
        active: boolean
        starred: boolean
        hasReport: boolean
        status: string
        checked: boolean
        trend: string
        color: string
        graph: string
        graphColor: string
        role: string
        plan_id: string
        end_date: string
        is_active: boolean
      },
      keepLoggedIn: boolean,
    ) {
      this.firstName = userData.firstName
      this.userName = userData.userName
      this.email = userData.email
      this.memberSince = userData.memberSince
      this.pfp = userData.pfp
      this.is2FAEnabled = userData.is2FAEnabled
      this.avatar = userData.avatar
      this.country = userData.country
      this.notes = userData.notes
      this.active = userData.active
      this.starred = userData.starred
      this.hasReport = userData.hasReport
      this.status = userData.status
      this.checked = userData.checked
      this.trend = userData.trend
      this.color = userData.color
      this.graph = userData.graph
      this.graphColor = userData.graphColor
      this.role = userData.role
      this.plan_id = userData.plan_id || ''
      this.end_date = userData.end_date || ''
      this.is_active = userData.is_active || false
      this.isLoggedIn = true
      this.keepLoggedIn = keepLoggedIn

      localStorage.setItem('firstName', userData.firstName)
      localStorage.setItem('userName', userData.userName)
      localStorage.setItem('email', userData.email)
      localStorage.setItem('memberSince', userData.memberSince)
      localStorage.setItem('pfp', userData.pfp)
      localStorage.setItem('is2FAEnabled', userData.is2FAEnabled.toString())
      localStorage.setItem('avatar', userData.avatar)
      localStorage.setItem('country', userData.country)
      localStorage.setItem('notes', userData.notes)
      localStorage.setItem('active', userData.active.toString())
      localStorage.setItem('starred', userData.starred.toString())
      localStorage.setItem('hasReport', userData.hasReport.toString())
      localStorage.setItem('status', userData.status)
      localStorage.setItem('checked', userData.checked.toString())
      localStorage.setItem('trend', userData.trend)
      localStorage.setItem('color', userData.color)
      localStorage.setItem('graph', userData.graph)
      localStorage.setItem('graphColor', userData.graphColor)
      localStorage.setItem('role', userData.role)
      localStorage.setItem('plan_id', this.plan_id)
      localStorage.setItem('end_date', this.end_date)
      localStorage.setItem('is_active', this.is_active.toString())
      localStorage.setItem('keepLoggedIn', keepLoggedIn.toString())

      console.log('User logged in:', userData)
    },

    logout() {
      this.firstName = ''
      this.userName = ''
      this.email = ''
      this.memberSince = ''
      this.pfp = ''
      this.is2FAEnabled = false
      this.avatar = ''
      this.country = ''
      this.notes = ''
      this.active = false
      this.starred = false
      this.hasReport = false
      this.status = ''
      this.checked = false
      this.trend = ''
      this.color = ''
      this.graph = ''
      this.graphColor = ''
      this.role = ''
      this.plan_id = ''
      this.end_date = ''
      this.is_active = false
      this.isLoggedIn = false
      this.keepLoggedIn = false

      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('userName')
      localStorage.removeItem('email')
      localStorage.removeItem('memberSince')
      localStorage.removeItem('pfp')
      localStorage.removeItem('is2FAEnabled')
      localStorage.removeItem('avatar')
      localStorage.removeItem('country')
      localStorage.removeItem('notes')
      localStorage.removeItem('active')
      localStorage.removeItem('starred')
      localStorage.removeItem('hasReport')
      localStorage.removeItem('status')
      localStorage.removeItem('checked')
      localStorage.removeItem('trend')
      localStorage.removeItem('color')
      localStorage.removeItem('graph')
      localStorage.removeItem('graphColor')
      localStorage.removeItem('role')
      localStorage.removeItem('plan_id')
      localStorage.removeItem('end_date')
      localStorage.removeItem('is_active')
      localStorage.removeItem('keepLoggedIn')

      console.log('User logged out')
    },

    async refreshToken() {
      try {
        const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/token/refresh/`, {
          refresh: localStorage.getItem('refreshToken'),
        })
        localStorage.setItem('token', response.data.access)
        console.log('Token refreshed:', response.data.access)
      } catch (error) {
        console.error('Error refreshing token:', error)
        this.logout()
      }
    },

    async checkAuth() {
      const token = localStorage.getItem('token')
      if (token) {
        const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000
        const now = new Date().getTime()
        console.log('Token expiration:', new Date(tokenExpiration))
        console.log('Current time:', new Date(now))
        if (now > tokenExpiration) {
          console.log('Token expired, refreshing...')
          if (this.keepLoggedIn) {
            await this.refreshToken()
          } else {
            this.logout()
          }
        } else {
          console.log('Token is still valid')
        }
      } else {
        console.log('No token found, logging out')
        this.logout()
      }
    },

    hasRole(roles: string[]) {
      return roles.includes(this.role)
    },
  },
})
