<template>
  <RouterView />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useColors } from 'vuestic-ui'

const { applyPreset } = useColors()

// Restaurar o tema selecionado do localStorage ao montar o aplicativo
onMounted(() => {
  const savedTheme = localStorage.getItem('theme')
  if (savedTheme) {
    applyPreset(savedTheme)
  }
})
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
